import styled, { css } from 'styled-components'

export const ErrorPageStyled = styled('div')(
  ({ theme }) => css`
    height: 100vh;
    width: 100vw;
    overflow-x: hidden;

    .MuiToolbar-root {
      color: ${theme.colors.white};
      background-color: ${theme.colors.brown};
    }
    
    main {
      width: 100%;
      height: 60vh;
      
      text-align: center;

      color: ${theme.colors.error}
      font-size: ${theme.global.typography.size['size-16']}
      
      ${theme.mixins.flexCenterH}
    }
  `
)
