import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { zodUndefinedIfInvalid } from '@digital-magic/react-common/lib/utils/zod'
import { ErrorPageType } from '@constants/errorPageType'
import { routes } from '@constants/routes'
import { Container, Toolbar, Typography } from '@mui/material'
import { AdminHeaderStyled } from '@layout/AdminLayout/AdminHeader/style'
import { Footer } from '@layout/Footer'
import { Link } from '@controls/Link'
import { ErrorPageStyled } from './ErrorPage.style'

type Params = {
  errorType: ErrorPageType
}

export const ErrorPage: React.FC = () => {
  const { t } = useTranslation()
  const params = useParams<Params>()

  const errorKey: ErrorPageType = zodUndefinedIfInvalid(params.errorType, ErrorPageType) ?? ErrorPageType.enum.Unknown

  return (
    <ErrorPageStyled>
      <AdminHeaderStyled position="sticky">
        <Container>
          <Toolbar disableGutters>
            <Typography variant="h6" noWrap component="div" sx={{ mr: 2, display: { xs: 'none', md: 'flex' } }}>
              <Link className="title" to={routes.Admin.Index}>
                {t('app.admin.title')}
              </Link>
            </Typography>
          </Toolbar>
        </Container>
      </AdminHeaderStyled>
      <Container>
        <main>
          <h1>{t('global.errors.title')}</h1>
          <Typography>{t(`global.errors.enum.${errorKey}`)}</Typography>
        </main>
      </Container>
      <Footer />
    </ErrorPageStyled>
  )
}
